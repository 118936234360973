<template>
  <b-card>
    <b-row>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Perawatan
        </h3>
        <br>
        
        <b-overlay
          :show="loading"
          variant="light"
          :opacity="0.5"
          rounded="sm"
        >

          <b-table
            striped
            responsive
            :items="items"
            :fields="fields"
          >

            <template #cell(month)="data">
              {{ data.item.month }}/{{ data.item.year }}
            </template>
            <template #cell(plan)="data">
              {{ data.item.plan|numFormat }}
            </template>
            <template #cell(realisation)="data">
              {{ data.item.realisation|numFormat }}
            </template>

          </b-table>

        </b-overlay>
      </b-col>
      <b-col cols="6">
        <h3 class="font-weight-bolder">
          Perbaikan
        </h3>
        <br>
        <template>
          <vue-apex-charts 
            ref="realtimeChart"
            type="line" 
            height="250" 
            :options="chartOptionsComputed" 
            :series="dataseries"
          />
        </template>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>

import {BCard, BRow, BCol, BTable, BOverlay} from 'bootstrap-vue'
import BaseInfo from '@/views/base/BaseInfo.vue'
import VueApexCharts from 'vue-apexcharts'
import { lineChartOptions } from '../../../chartOptions'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BaseInfo,
    BTable,
    BOverlay,
    VueApexCharts
  },

  data() {
    return {
      dataurl:"/vehicle/"+this.$route.params.id+"/maintenance/chart",
      fields: [
        { key: 'month', label: 'Bulan'},
        { key: 'plan', label: 'Rencana'},
        { key: 'realisation', label: 'Realisasi'},
      ],
      items:[],
      dataseries:[],
      datalabel:[],
      loading:false
    }
  },
  computed: {
    chartOptionsComputed() {
      const options = JSON.parse(JSON.stringify(lineChartOptions))
      options.xaxis = {type:'string', categories: this.datalabel}
      return options
    },
  },
  mounted(){
    this.fetchData()
  },
  methods: {
    fetchData(){
      this.loading = true
      this.$http.get(this.dataurl)
        .then(res  => {
          this.items = res.data.issue
          let plan = []
          let realisation = []
          this.dataseries = []
          this.datalabel = []
          for(let i = 0; i<res.data.service.length; i++){
            this.datalabel.push(res.data.service[i]['month']+'/'+res.data.service[i]['year'])
            plan.push(res.data.service[i]['plan'] == null?0:res.data.service[i]['plan'])
            realisation.push(res.data.service[i]['realisation'] == null?0:res.data.service[i]['realisation'])
            // this.dataseries.push(i+1)
          }
          this.dataseries.push(
            {name:'rencana', data:plan}, {name:'realisasi', data:realisation}
          )
          
          this.$refs.realtimeChart.updateOptions({ xaxis: {type:'string', categories: this.datalabel} })
        })
        .catch(()=>{
          this.$bvToast.toast('Something wrong', {
            title: 'Fail',
            solid: true,
            variant:'warning'
          })
        })
        .finally(() => this.loading = false)
    }
  }
}
</script>